import LayoutLogin from "@/layouts/LayoutLogin";
export default {
  name: "Home",
  created() {
    this.$emit(`update:layout`, LayoutLogin);
  },
  mounted() {
    this.G_darkThemeInit();
    // $("#setThemeDark").prop("checked", true);

    // $(".owl-carousel").owlCarousel({
    //   margin: 10,
    //   nav: false,
    //   loop: true,
    //   autoplay: true,
    //   autoplayHoverPause: true,
    //   lazyLoad: true,
    //   responsive: {
    //     0: {
    //       items: 1,
    //     },
    //     600: {
    //       items: 1,
    //     },
    //     1000: {
    //       items: 1,
    //     },
    //   },
    // });
  },
  methods: {
    loginAct() {
      let myForm = document.getElementById("formData");
      let formData = new FormData(myForm);
      if (this.$refs.form.validate()) {
        this.$refs.btnAct.load(true);

        //       localStorage.setItem('token', 'tokentest');
        // this.$router.push('/');

        this.G_PostAny("signin", formData)
          .then((res) => {
            console.log(res);

            if (res.status) {
              this.$refs.notif.warning(res.data.message);
            } else {
              localStorage.setItem("token", res.token);

              this.G_GetAny("user/me").then((res) => {
                // localStorage.setItem('userLogin', res.data);
                localStorage.setItem("userLogin", JSON.stringify(res.data));
                this.$router.push("/");
              });
            }
          })
          .finally(() => {
            this.$refs.btnAct.load(false);
          });
      } else {
        this.$refs.notif.warning("Lengkapi Isian Terlebih Dahulu");
      }
    },
  },
};
